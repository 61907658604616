import { cn } from '@virginexperiencedays/tailwind';
import type { Product } from '@virginexperiencedays/products';

import { Image } from '../../layout/Image';
import { DisplayHeading } from '../../typography/DisplayHeading';
import { LinkWrap } from '../../navigation/LinkWrap';
import { roundPrice, round } from '../../utils/roundPrice';

type EditorsPickCardProps = {
  product: Product;
  cardImageClassName?: string;
};

export const EditorsPickCard = ({ product, cardImageClassName }: EditorsPickCardProps) => {
  const { title, price, media, url } = product ?? {};

  if (!media?.mainImage?.url) return null;

  return (
    <LinkWrap className="relative flex flex-col" href={url}>
      <Image
        fill
        className={cn('aspect-video rounded', cardImageClassName)}
        src={media.mainImage.url}
        sizes="(min-width: 768px) 320px, 80vw"
        alt={title}
      />
      <DisplayHeading
        className="text-neutral-strong mb-4 mt-2 text-base font-normal leading-normal"
        size="3"
      >
        {title}
      </DisplayHeading>
      <div>
        <span>
          {price?.percentOff && price?.rrp ? (
            <div>
              <div
                className="text-neutral bg-background-page absolute left-2 top-2 rounded-sm px-1 py-0.5 text-xs font-semibold"
                data-testid="percent-off"
              >
                {round(price.percentOff, 0)}% OFF
              </div>
              <div className="text-grey-600 flex items-center text-sm">
                <span className="text-neutral-strong pr-1 text-base font-bold leading-normal">
                  {roundPrice(price.displayPrice)}
                </span>
                <span className="mr-2 line-through">{roundPrice(price.rrp)}</span>
              </div>
            </div>
          ) : price?.fixedAmountOff || price?.rrp > price?.displayPrice ? (
            <div className="text-grey-600 flex items-center text-sm">
              <span
                className="text-neutral-strong pr-1 text-base font-bold leading-normal"
                data-testid="discounted-price"
              >
                {roundPrice(price.displayPrice)}
              </span>
              <span className="mr-2 line-through">{roundPrice(price.rrp)}</span>
            </div>
          ) : (
            <span
              className="text-neutral-strong pr-1 text-base font-bold leading-normal"
              data-testid="regular-price"
            >
              {roundPrice(price.displayPrice)}
            </span>
          )}
        </span>
      </div>
    </LinkWrap>
  );
};
