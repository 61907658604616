export const MINIMUM_RECENT_REVIEW_RATING = 3;
export const MINIMUM_TOTAL_CUSTOMER_REVIEW = 4;

export const DEFAULT_IMAGE =
  'https://images.prismic.io/virginexperiencedays/6288bdd2-0da0-4db1-bc60-1403f1840a19_gray.jpeg?auto=compress,format';

export const blogContentRelationship = [
  'blog_post_page.uid',
  'blog_post_page.title',
  'blog_post_page.og_description',
  'blog_post_page.coverimage',
  'blog_post_page.og_image',
];
export const blogListingContentRelationship = ['blog_listing_page.title'];
export const postListingContentRelationship = ['post_listing_page.title'];
export const ttdContentRelationship = [
  'post.uid',
  'post.title',
  'post.og_description',
  'post.coverimage',
  'post.og_image',
];
export const categoryContentRelationship = [
  'category_listing_page.category_type',
  'category_listing_page.title',
  'category_listing_page.uid',
  'category_listing_page.og_description',
  'category_listing_page.og_image',
  'category_listing_page.legacy_og_image',
  'category_listing_page.display_title_override',
];

export const categoryOverrideContentRelationship = [
  'category_override_listing_page.title',
  'category_override_listing_page.uid',
  'category_override_listing_page.og_description',
  'category_override_listing_page.og_image',
  'category_override_listing_page.legacy_og_image',
];

export const locationContentRelationship = [
  'location_listing_page.title',
  'location_listing_page.uid',
  'location_listing_page.og_description',
  'location_listing_page.og_image',
  'location_listing_page.legacy_og_image',
];

export const ratingContentRelationship = [
  'rating_listing_page.title',
  'rating_listing_page.uid',
  'rating_listing_page.og_description',
  'rating_listing_page.og_image',
  'rating_listing_page.legacy_og_image',
];

export const priceRangeContentRelationship = [
  'price_range_listing_page.title',
  'price_range_listing_page.uid',
  'price_range_listing_page.og_description',
  'price_range_listing_page.og_image',
  'price_range_listing_page.legacy_og_image',
];

export const curatedCarouselTabContentRelationship = [
  'curated_carousel_tab.title',
  'curated_carousel_tab.products',
];

export const clpContentRelationship = [
  ...categoryContentRelationship,
  ...categoryOverrideContentRelationship,
  ...locationContentRelationship,
  ...ratingContentRelationship,
  ...priceRangeContentRelationship,
];

// Slices that needs real products based upon the given id
export const productTypeSlices = ['cardsProductIDs', 'product_grid', 'gift_card_price_selector'];
// Slices that needs products from algolia based upon given category and preset presetType
export const algoliaTypeSlices = ['cardsProductsAlgolia'];

// Slices that are using current mainCategory data logic based upon data coming from algolia
export const mainCategoryAlgoliaTypeSlices = ['childCategories', 'siblingCategories'];
export const slicesWithSSRData = ['product_grid'];

export const defaultHeroBannerBgImages = {
  development: {
    desktop:
      'https://images.prismic.io/virginexperiencedays-dev/7f5d17fa-3c93-4f30-8161-55da84ecc231_herostyle-01-bg-desktop.png',
    tablet:
      'https://images.prismic.io/virginexperiencedays-dev/de720a96-02cf-4846-a4b6-25e83a11679a_herostyle-01-bg-tablet.png',
    mobile:
      'https://images.prismic.io/virginexperiencedays-dev/579d3162-2e87-4120-a4c4-a2fcbc7afbb6_herostyle-01-bg-mobile.png',
  },
  production: {
    desktop:
      'https://images.prismic.io/virginexperiencedays/77eaa3ae-89b5-412d-975a-05f0541d1b1c_herostyle-01-bg-desktop.png',
    tablet:
      'https://images.prismic.io/virginexperiencedays/ccfee446-76a8-4f10-a778-efd5e69e6041_herostyle-01-bg-tablet.png',
    mobile:
      'https://images.prismic.io/virginexperiencedays/3ed7fbe0-646b-4c28-b59d-e6f259bf53f1_herostyle-01-bg-mobile.png',
  },
};

// Hero Style 05
export const defaultHeroStyle05BgImage = {
  development:
    'https://images.prismic.io/virginexperiencedays-dev/6ef88145-c850-4c96-9eac-3c18a72349aa_HeroStyle05_bg_default.jpg',
  production:
    'https://images.prismic.io/virginexperiencedays/c40be302-c1bc-45f9-ad28-3ad3ed3f5176_HeroStyle05_bg_default.jpg',
};

export const maskSquircleHeroStyle05 = {
  development: {
    mobile:
      'https://images.prismic.io/virginexperiencedays-dev/099edae0-f5a8-42dc-b907-00f00deeda63_HeroStyle05_mask_mobile.png',
    tablet:
      'https://images.prismic.io/virginexperiencedays-dev/faee0eef-4cd5-4525-9773-9d0b544101f0_HeroStyle05_mask_tablet.png',
    desktop:
      'https://images.prismic.io/virginexperiencedays-dev/98d01b4a-cfaa-461c-bfe9-95b221b48f28_HeroStyle05_mask_desktop.png',
  },
  production: {
    mobile:
      'https://images.prismic.io/virginexperiencedays/c03d85da-c26d-41f3-aa33-99aaab8f4c42_HeroStyle05_mask_mobile.png',
    tablet:
      'https://images.prismic.io/virginexperiencedays/bfe4fdc9-88ad-42e2-887c-c1df72279394_HeroStyle05_mask_tablet.png',
    desktop:
      'https://images.prismic.io/virginexperiencedays/132d5b2d-059b-4459-a7bd-1aad9951a4bc_HeroStyle05_mask_desktop.png',
  },
};

// Used in News Letter
type ImageInfo = {
  src: string;
  width: number;
  height: number;
};
type ResponsiveImageInfo = {
  desktop: ImageInfo;
  mobile: ImageInfo;
};
type NewsLetterImageConfig = {
  development: ResponsiveImageInfo;
  production: ResponsiveImageInfo;
};
export const newsLetterImage: NewsLetterImageConfig = {
  development: {
    desktop: {
      src: 'https://images.prismic.io/virginexperiencedays-dev/c056905d-d278-4d52-b512-5a4e8ec171be_Newsletter+-+Desktop.png?auto=compress,format',
      width: 560,
      height: 300,
    },
    mobile: {
      src: 'https://images.prismic.io/virginexperiencedays-dev/16bad70e-aff9-4d69-ab45-0aed04df94f3_Newsletter+-+Mobile.png?auto=compress,format',
      width: 390,
      height: 160,
    },
  },
  production: {
    desktop: {
      src: 'https://images.prismic.io/virginexperiencedays/287c3c76-90e5-4ea1-ae49-6750f82c43b7_Newsletter+-+Desktop.png?auto=compress,format',
      width: 560,
      height: 300,
    },
    mobile: {
      src: 'https://images.prismic.io/virginexperiencedays/aab80fd2-9028-4bf3-84ec-3dc7957447a7_Newsletter+-+Mobile.png?auto=compress,format',
      width: 390,
      height: 160,
    },
  },
};

// Used in product grid gift card tile
export const defaultGiftCardImage = {
  src: 'https://images.prismic.io/virginexperiencedays/b270654d-0b54-4de7-9a22-f0a88671a77e_gift-card-iso.png',
  alt: 'Virgin Experience Days Gift Card',
  width: 600,
  height: 400,
};

// Used in Hero Banner
export const maskSquircle = {
  development:
    'https://images.prismic.io/virginexperiencedays-dev/46027003-4e5a-42fa-b6f4-c9d0d2b2c9fa_squircle.png',
  production:
    'https://images.prismic.io/virginexperiencedays/b681f218-3fac-4534-be88-633980d60bb7_squircle.png',
};

export const cardTypeNames = {
  product_card: 'Product Card',
  display_card: 'Display Card',
  category_card: 'Category Card',
  text_over_card: 'Text Over Card',
  basic_card: 'Basic Card',
};

export enum sliceTypes {
  NUGGET = 'nugget',
  BUTTON = 'button',
  CAROUSEL = 'carousel_structure',
  CAROUSEL_CTA = 'carousel_cta',
  CHUNKY_MESSAGE = 'chunky_message',
  COLUMN = 'column_structure',
  CUSTOM_IMAGE = 'image_slice',
  GRID = 'grid_structure',
  ICON_BANNERS = 'icon_banners',
  FEATURED_VISUAL_CARDS = 'featured_visual_cards',
  PROMO_BANNER = 'promo_banner',
  HERO = 'hero_style01',
  HERO_02 = 'hero_style02',
  HERO_03 = 'hero_style03',
  HERO_04 = 'hero_style04',
}

export enum documentTypes {
  OCCASION = 'category_listing_page',
  FEATURE = 'category_listing_page',
  CATEGORY = 'category_listing_page',
  PRICE_RANGE = 'price_range_listing_page',
  RATING = 'rating_listing_page',
  LOCATION = 'location_listing_page',
  CATEGORY_OVERRIDE = 'category_override_listing_page',
}

export const DOCUMENT_TYPE_ENDPOINT_MAP = {
  category_listing_page: 'categories',
  location_listing_page: 'locations',
  category_override_listing_page: 'category-overrides',
  price_range_listing_page: 'price-ranges',
  rating_listing_page: 'ratings',
};

export const SLICE_TRACKING_LABELS = {
  nugget: 'Nugget',
  icon_banners: 'IconCard',
  button: 'Button',
  hero_style01: 'Hero',
  hero_style02: 'Hero 02',
  hero_style03: 'Hero 03',
  hero_style04: 'Hero 04',
  hero_style05: 'Hero 05',
  carousel_cta: 'Carousel CTA',
  featured_visual_cards: 'Featured Visual Card',
  promo_banner: 'Promo Strip',
  chunky_message: 'Chunky Message CTA',
  carousel_with_tabs: 'Tabbed Carousel Tab',
  gift_card_price_selector: 'Add Gift Card to Basket',
  carousel_end_card_cta: 'Carousel End Card CTA',
};

export const LCP_PREFETCH_SLICE_COUNT = 1;
// Assumes zero-based numbering
export const LCP_PREFETCH_CAROUSEL_COUNT = 1;
