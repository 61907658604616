import { LinkWrap } from '../../navigation/LinkWrap';
import { IconStarSmall } from '../../icons/IconStarSmall';
import { BodyText } from '../../typography/BodyText';

import { ProductCardImage } from './ProductCardImage';
import { RatingsAndReviews } from './ProductRatingAndReviews';
import { formatLocations, formatNoOfLocations } from '../../utils/productLocation';
import { getProductCardPrices } from '../../utils/productPricing';

import type { CarouselProductCardProps } from './types';

export const CarouselProductCard = ({
  title,
  locations,
  noOfLocations,
  averageRating,
  totalReviews,
  src,
  alt,
  rrp,
  percentOff,
  displayPrice,
  isExclusive = false,
  isNew = false,
  isOnSale = false,
  aspectRatio = '1/1',
  isCollectionProduct = false,
  isHrefExternal = false,
  productPromo,
  isBlackFridayProduct,
  isCyberMondayProduct,
  isChristmasProduct,
  isEasterProduct,
  isMothersDayProduct,
  isFathersDayProduct,
  isValentinesProduct,
  slug,
  sku,
  promocode,
  priority,
  onClick = () => {},
}: CarouselProductCardProps) => {
  const productUrl = isCollectionProduct ? `/collection/${slug}` : `/product/${slug}`;

  const hasRating = averageRating > 0 && totalReviews > 0 && !isCollectionProduct;

  const { currentPrice, pastPrice, roundedPercentOff } = getProductCardPrices({
    displayPrice,
    rrp,
    percentOff,
  });

  const normalizedLocations = formatLocations(locations) ?? formatNoOfLocations(noOfLocations);

  if (!currentPrice) return null;

  return (
    <LinkWrap
      className="bg-background-elevation-raised border-border-neutral group flex h-full flex-col gap-1 overflow-hidden rounded-lg border no-underline"
      href={productUrl}
      isExternal={isHrefExternal}
      onClick={onClick}
      dataProductId={promocode ? `${sku} ${promocode}` : sku}
    >
      <ProductCardImage
        cardImage={src}
        cardTitle={alt ?? title}
        isOnSale={isOnSale}
        isExclusive={isExclusive}
        isNew={isNew}
        productPromo={productPromo}
        isBlackFridayProduct={isBlackFridayProduct}
        isCyberMondayProduct={isCyberMondayProduct}
        isChristmasProduct={isChristmasProduct}
        isEasterProduct={isEasterProduct}
        isMothersDayProduct={isMothersDayProduct}
        isFathersDayProduct={isFathersDayProduct}
        isValentinesProduct={isValentinesProduct}
        aspectRatio={aspectRatio}
        priority={priority}
      />
      <div
        data-testid="carousel-product-card"
        data-productid={promocode ? `${sku} ${promocode}` : sku}
        className="flex h-full flex-col justify-start gap-2 px-2 pt-2"
      >
        <h3
          data-testid="product-card-title"
          className="group-hover:text-link text-neutral-strong font-inter m-0 line-clamp-2 text-sm font-medium leading-normal"
        >
          {title}
        </h3>
        {!!normalizedLocations && (
          <BodyText
            data-testid="product-card-location-count-container"
            size="small"
            leading="base"
            className="text-neutral-faded m-0"
          >
            {normalizedLocations}
          </BodyText>
        )}
        {hasRating && (
          <RatingsAndReviews
            averageRating={averageRating}
            count={totalReviews}
            countClassName="text-xs leading-base font-[500]"
            Icon={IconStarSmall}
          />
        )}
      </div>
      <div className="flex flex-row flex-wrap items-end gap-2 px-2 pb-2 pt-4">
        <BodyText
          data-testid="product-card-current-price"
          className="text-neutral-strong m-0 font-semibold"
          leading="none"
        >
          {currentPrice}
        </BodyText>
        {pastPrice && (
          <BodyText
            data-testid="product-card-past-price"
            className="text-neutral-faded m-0 line-through"
            size="small"
            leading="none"
          >
            {pastPrice}
          </BodyText>
        )}
        {pastPrice && !!roundedPercentOff && (
          <BodyText
            className="text-tones-positive-700 font-semibold"
            size="extraSmall"
            leading="none"
          >
            You save {roundedPercentOff}%
          </BodyText>
        )}
      </div>
    </LinkWrap>
  );
};
