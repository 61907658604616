import { cn } from '@virginexperiencedays/tailwind';
import { useFeatureFlagsServer } from '@virginexperiencedays/feature-flags/src/useFlags';

import { useHeaderContext } from '@virginexperiencedays/header-2024';
import {
  type ProductCardProps as ProductCardV1Props,
  ProductCard as ProductCardV1,
} from '@virginexperiencedays/components-v2/src/cards/Product';
import { ProductCardV2 } from '@virginexperiencedays/components-v2/src/cards/ProductV2';
import {
  EditorsPick,
  type EditorsPickProps,
} from '@virginexperiencedays/components-v2/src/carousels/EditorsPick';
import {
  TopExperiences,
  type TopExperiencesProps,
} from '@virginexperiencedays/components-v2/src/carousels/TopExperiences';

import { getTrackingData, handleProductCardImageChange, trackProductCardV2Click } from './tracking';

import type { MouseEvent } from 'react';
import type { GridProduct } from '@virginexperiencedays/search/types';
import type { CarouselCallbackProps } from '@virginexperiencedays/components-v2/src/carousels/EmblaCarousel';

export type ProductCardTrackingProps = {
  pageType?: string;
  module?: string;
};

export type ProductGridCardProps = {
  index: number;
  item: GridProduct & {
    // images override from GiftFinder
    images?: { src: string; alt: string }[];
  };
  className?: string;
  titleClassName?: string;
  priority?: boolean;
  /**
   * Flag whether to prefetch the link (NextJS Link only)
   */
  prefetch?: boolean;
  isMinimal?: boolean;
  tracking?: ProductCardTrackingProps;
};

export const useIsProductCardsV2 = () => {
  const [isEnabledServer] = useFeatureFlagsServer();

  return isEnabledServer('FF_product_cards_v2');
};

/**
 * Used in Product Grid
 */
export const ProductGridCard = ({
  className,
  titleClassName,
  index,
  priority,
  prefetch,
  isMinimal,
  tracking,
  item,
}: ProductGridCardProps) => {
  const isProductCardsV2 = useIsProductCardsV2();
  const { trackingPageType } = useHeaderContext();

  const sharedProps = {
    ...item,
    percentOff: item.price.percentOff,
    displayPrice: item.price.displayPrice,
    averageRating: item.reviews.averageRating,
    priority: priority && index === 0,
    prefetch,
  } as const;

  if (!isProductCardsV2) {
    return (
      <ProductCardV1
        {...sharedProps}
        className={className}
        contentClassName={cn('gap-1', isMinimal ? 'p-0' : 'px-2 pb-2 md:p-0')}
        titleClassName="leading-normal font-[500] md:font-[500]"
        aspectRatio="1/1"
        isMinimal={isMinimal}
        isOnSale={item.price.displayPrice !== item.price.rrp}
        rrp={item.price.displayPrice !== item.price.rrp ? item.price.rrp : null}
        src={item.media.mainImage.url}
        totalReviews={item.reviews.count}
      />
    );
  }

  const trackingData = getTrackingData({
    item,
    tracking: { ...tracking, pageType: tracking?.pageType || trackingPageType },
    index,
  });

  const images = Array.isArray(item?.images)
    ? item.images
    : [item.media.mainImage, ...(item.media.images || [])].map((image, i) => ({
        src: image.url,
        alt: image.altText || `${item.title} ${i + 1}`,
      }));

  return (
    <ProductCardV2
      {...sharedProps}
      titleClassName={titleClassName}
      rrp={item.price.rrp}
      images={images}
      isImageCarousel
      onImageCarouselSwipe={(props: CarouselCallbackProps) =>
        handleProductCardImageChange({ ...props, trackingData })
      }
      onImageCarouselNavClick={(props: CarouselCallbackProps) =>
        handleProductCardImageChange({ ...props, trackingData })
      }
      onClick={(e: MouseEvent<HTMLElement>) => {
        trackProductCardV2Click(e, { trackingData });

        // includes tracking from web/utils/search/mapHitsToProducts.ts@mapHitsToProducts
        item?.onClick(e);
      }}
    />
  );
};

/**
 * Used in Product Grid as filler Gift Card when there are featured products inserted in the grid
 */
export const ProductGridGiftCard = ({
  className,
  titleClassName,
  item,
  index,
  prefetch,
}: {
  className?: string;
  titleClassName?: string;
  item: ProductCardV1Props;
  index?: number;
  prefetch?: boolean;
}) => {
  const isProductCardsV2 = useIsProductCardsV2();
  const { trackingPageType } = useHeaderContext();

  if (!isProductCardsV2) {
    return <ProductCardV1 className={className} prefetch={prefetch} {...item} />;
  }

  const { title, sku, src, slug, displayPrice } = item ?? {};

  const product = {
    productPromo: null,
    title,
    images: [{ src, alt: title }],
    slug,
    sku,
    price: { displayPrice, percentOff: 0, rrp: null },
    displayPrice,
    locations: [],
    categories: ['gift-cards'],
  };

  const trackingData = getTrackingData({
    item: product,
    tracking: { pageType: trackingPageType, module: 'product_grid' },
    index,
  });

  const trackingProps = {
    onImageCarouselSwipe: (props: CarouselCallbackProps) =>
      handleProductCardImageChange({ ...props, trackingData }),
    onImageCarouselNavClick: (props: CarouselCallbackProps) =>
      handleProductCardImageChange({ ...props, trackingData }),
    onClick: (e: MouseEvent<HTMLElement>) => {
      trackProductCardV2Click(e, { trackingData });

      // retain any existing onClick
      item?.onClick(e);
    },
  };

  return (
    <ProductCardV2
      className={className}
      titleClassName={titleClassName}
      prefetch={prefetch}
      {...product}
      {...trackingProps}
    />
  );
};

/**
 * Used in Blog / TTD
 */
export const EditorsPickRenderer = ({
  productsList,
  tracking,
  ...props
}: EditorsPickProps & {
  tracking?: ProductCardTrackingProps;
}) => {
  const isProductCardsV2 = useIsProductCardsV2();
  const { trackingPageType } = useHeaderContext();

  if (!isProductCardsV2) {
    return <EditorsPick {...props} productsList={productsList} />;
  }

  const mappedProducts = productsList?.map((item, index) => {
    const trackingData = getTrackingData({
      item,
      tracking: { pageType: tracking?.pageType || trackingPageType, module: 'editors_pick' },
      index,
    });
    return {
      ...item,
      onImageCarouselSwipe: (props: CarouselCallbackProps) =>
        handleProductCardImageChange({ ...props, trackingData }),
      onImageCarouselNavClick: (props: CarouselCallbackProps) =>
        handleProductCardImageChange({ ...props, trackingData }),
      onClick: (e: MouseEvent<HTMLElement>) => {
        trackProductCardV2Click(e, { trackingData });

        // retain any existing onClick
        item?.onClick(e);
      },
    };
  });

  return <EditorsPick {...props} productsList={mappedProducts} isProductCardsV2 />;
};

/**
 * Used in SRP - No Results
 */
export const TopExperiencesRenderer = ({
  products,
  tracking,
  ...props
}: TopExperiencesProps & {
  tracking?: ProductCardTrackingProps;
}) => {
  const isProductCardsV2 = useIsProductCardsV2();
  const { trackingPageType } = useHeaderContext();

  if (!isProductCardsV2) {
    return <TopExperiences {...props} products={products} />;
  }

  const mappedProducts = products?.map((item, index) => {
    const trackingData = getTrackingData({
      item,
      tracking: { pageType: tracking?.pageType || trackingPageType, module: 'top_experiences' },
      index,
    });
    return {
      ...item,
      onImageCarouselSwipe: (props: CarouselCallbackProps) =>
        handleProductCardImageChange({ ...props, trackingData }),
      onImageCarouselNavClick: (props: CarouselCallbackProps) =>
        handleProductCardImageChange({ ...props, trackingData }),
      onClick: (e: MouseEvent<HTMLElement>) => {
        trackProductCardV2Click(e, { trackingData });

        // retain any existing onClick
        item?.onClick(e);
      },
    };
  });

  return <TopExperiences {...props} products={mappedProducts} isProductCardsV2 />;
};
