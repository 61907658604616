import { useEffect } from 'react';
import { useRouter } from 'next/compat/router';

type Opts = {
  sessionStorage?: boolean; // defaults to use localStorage instead
};
/**
 * Convenience hook for setting items into local storage that's only fired
 * whenever the route changes. This can be useful for e.g. setting a tracking
 * item in storage for each CLP.
 *
 * @param obj Object where each key-value pair corresponds to an item you want to store in local storage
 */
export const useRouteStorage = (obj: Record<string, any>, deps?: any[]) => {
  const router = useRouter();

  useEffect(() => {
    if (!router?.isReady) return;
    if (!window.localStorage) return;

    for (const [key, value] of Object.entries(obj)) {
      const serializedValue = typeof value === 'string' ? value : JSON.stringify(value);
      window.localStorage.setItem(key, serializedValue);
    }
  }, [router?.isReady, ...(deps ?? [])]);
};

export const getStorageItem = (key: string, opts?: Opts) => {
  const storage = opts?.sessionStorage ? window.sessionStorage : window.localStorage;
  if (!storage) return;

  return storage.getItem(key);
};

export const setStorageItem = (key: string, value: any, opts?: Opts) => {
  const storage = opts?.sessionStorage ? window.sessionStorage : window.localStorage;
  if (!storage) return;

  storage.setItem(key, value);
};
