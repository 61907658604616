import { cn } from '@virginexperiencedays/tailwind';

import { IconStarSmall } from '../../icons/IconStarSmall';
import { roundStarRating } from '../../utils/productRating';

import { StarRating, type StarRatingProps } from '../../StarRating';

export type ReviewsProps = {
  averageRating: number;
  count: number;
  maxRating?: number;
  className?: string;
  countClassName?: string;
  Icon?: StarRatingProps['Icon'];
};

export const RatingsAndReviews = ({
  averageRating,
  count,
  className,
  countClassName,
}: ReviewsProps) => {
  if (typeof averageRating !== 'number' || typeof count !== 'number') return null;

  // Apply rating logic to decide whether to render Star Rating or not
  const roundedRating = roundStarRating(averageRating);

  if (!roundedRating) return null;

  return (
    <div
      data-testid="product-card-ratings-and-reviews"
      className={cn('flex flex-row flex-nowrap items-end gap-2', className)}
    >
      <StarRating rating={averageRating} Icon={IconStarSmall} />
      <span className={cn('text-neutral-faded text-xs', countClassName)}>({count})</span>
    </div>
  );
};
