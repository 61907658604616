import { useAspectRatioSupported } from '@virginexperiencedays/hooks';
import { cn } from '@virginexperiencedays/tailwind';

import { Image } from '../../layout/Image';
import { IconFavourite } from '../../icons/IconFavourite';
import type { PromotionBadgeType } from '../../PromotionBadge';
import {
  mapAspectRatio,
  mapAspectRatioPolyfill,
  type ResponsiveAspectRatio,
} from '../../utils/mapAspectRatio';
import { getCardBadgeText } from '../../utils/getCardBadgeText';
import { styles } from '../CarouselProduct/ProductCardImage';
import { PromotionTag } from '../Product/PromotionTag';

export type MediaCardProps = {
  className?: string;
  src: string;
  alt: string;
  /** Round corners */
  rounded?: boolean;
  /** set the aspect ratio of the image */
  aspectRatio?: ResponsiveAspectRatio;
  /** if value, shows badge in top left corner */
  /** if true, shows badge in top right corner */
  isExclusive?: boolean;
  /** if true, and exclusive badge not true, shows badge in top right corner */
  isNew?: boolean;
  /** shows badge in top right corner, overrides exclusive and new */
  promoType?: PromotionBadgeType;
  savingBadge?: number;
  /** if true, shows badge in top right corner */
  newBadge?: boolean;
  /** if true, shows badge in top right corner */
  favourite?: boolean;
  dataTestId?: string;
  imageClassName?: string;
  /** Whether to force priority image loading */
  priority?: boolean;
};

export const MediaCard = ({
  className,
  imageClassName,
  src,
  alt,
  savingBadge,
  isNew = false,
  isExclusive = false,
  promoType,
  rounded = false,
  aspectRatio = '4/3',
  favourite = false,
  dataTestId = 'media-card',
  priority,
}: MediaCardProps) => {
  const { isSupported } = useAspectRatioSupported();

  const badgeText = getCardBadgeText({ isNew, isExclusive });

  return (
    <div
      className={cn(
        'bg-background-neutral-faded relative w-full justify-between overflow-hidden leading-none',
        rounded && 'rounded',
        className
      )}
      data-testid={dataTestId}
    >
      {savingBadge ? (
        <div
          className="absolute left-4 top-4 z-[10] rounded-sm bg-white px-1 py-0.5 text-xs font-semibold uppercase leading-none tracking-wider text-black shadow-sm"
          data-testid="media-card-save-badge"
        >
          {savingBadge}% Off
        </div>
      ) : null}

      {/* (Top Left) Badge Text (exclusive / new) - displays if there is no promo text */}
      {badgeText && !promoType && (
        <div
          className="bg-background-neutral-strong absolute right-4 top-4 z-[10] rounded px-0.5 py-1 text-xs font-semibold uppercase leading-none tracking-tighter text-white shadow-sm"
          data-testid="media-card-new-or-exclusive-badge"
        >
          {badgeText}
        </div>
      )}
      {/* (Top Left) Promo Text - overrides exclusive / new badge */}
      <PromotionTag type={promoType} className={styles.badgeBase} />

      {favourite ? (
        <div
          className="absolute bottom-4 right-4 z-[5] leading-none"
          data-testid="media-card-favourite-badge"
        >
          <IconFavourite className="text-white hover:cursor-pointer hover:[&>path]:[fill-rule:nonzero]" />
        </div>
      ) : null}
      <Image
        className={cn(
          'image:object-cover h-full w-full',
          'group-hover:scale-105', // overwrite it via image classname if needed
          isSupported && mapAspectRatio(aspectRatio),
          !isSupported && mapAspectRatioPolyfill(aspectRatio),
          imageClassName
        )}
        dataTestId="media-card-image"
        src={src}
        alt={alt}
        priority={priority}
        fill
      />
    </div>
  );
};
