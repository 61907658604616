import {
  getLocationText,
  TRACKING_IMAGE_CLICK_CLASS,
} from '@virginexperiencedays/components-v2/src/cards/ProductV2';

import {
  type ProductInteractionProps,
  productCard as trackProductCard,
  ProductCardInteraction,
} from '../tracking/interactions';

import type { MouseEvent } from 'react';
import type { CarouselCallbackProps } from '@virginexperiencedays/components-v2/src/carousels/EmblaCarousel';
import type { ProductCardTrackingProps } from '.';

export const getTrackingData = ({
  item,
  tracking,
  index,
}: {
  item: unknown;
  tracking?: ProductCardTrackingProps;
  index: number;
}) => {
  let formattedItem = {} as Record<string, any>;
  if (typeof item === 'object' && item !== null) {
    formattedItem = item;
  }
  return {
    ...formattedItem,
    pageType: tracking?.pageType,
    module: tracking?.module,
    position: index + 1,
    location: getLocationText({
      locations: 'locations' in formattedItem ? formattedItem.locations : [],
      categories: 'categories' in formattedItem ? formattedItem.categories : [],
    }),
  } as const;
};

export const handleProductCardImageChange = ({
  currentIndex: imgIndex,
  totalSlides,
  dir,
  gesture,
  trackingData,
}: CarouselCallbackProps & { trackingData?: Partial<ProductInteractionProps> }) => {
  const interaction = {
    swipe: {
      prev: ProductCardInteraction.SwipePrev,
      next: ProductCardInteraction.SwipeNext,
    },
    click: {
      prev: ProductCardInteraction.ClickPrev,
      next: ProductCardInteraction.ClickNext,
    },
  };
  trackProductCard({
    ...(trackingData || {}),
    imageInteracted: `${imgIndex + 1} of ${totalSlides}`,
    interaction: interaction?.[gesture]?.[dir],
  } as ProductInteractionProps);
};

export const trackProductCardV2Click = (
  e: MouseEvent<HTMLElement>,
  { trackingData }: { trackingData?: Partial<ProductInteractionProps> }
) => {
  const currentTarget = e.currentTarget as HTMLElement;
  const target = e.target as HTMLElement;

  trackProductCard({
    ...(trackingData || {}),
    // e.currentTarget gets the actual anchor element that contains data-activeimage
    imageInteracted: currentTarget?.dataset?.activeimage,

    // e.target gets the specific element where it clicked, and looks for the parent element of the image carousel if the pointer is inside it
    interaction: target.closest(`.${TRACKING_IMAGE_CLICK_CLASS}`)
      ? ProductCardInteraction.ClickImage
      : ProductCardInteraction.ClickCard,
  } as ProductInteractionProps);
};
