import { cardTypeNames, SLICE_TRACKING_LABELS } from '@virginexperiencedays/cms/constants';
import { Product } from '@virginexperiencedays/products/src/types';
import { addToDataLayer } from '../../../tracking/gtm/utils';
import clickProduct from '../../../tracking/gtm/interactions/clickProduct';
import { hitImpression } from '.';

export function slice({
  slug,
  label,
  sliceType,
  variation = 'default',
  cardType,
  pageType = 'Category',
  action = 'Clicked',
}: {
  slug: string;
  label: string;
  sliceType: string;
  variation?: string;
  cardType?: string;
  pageType?: string;
  action?: string;
}) {
  let sliceName = [sliceType, variation, cardType].filter((val) => !!val).join(':'); // fallback

  if (SLICE_TRACKING_LABELS?.[sliceType]) sliceName = SLICE_TRACKING_LABELS?.[sliceType];
  // Product Card Carousel/Grid
  else if (variation === 'cardsProductIDs') sliceName = cardTypeNames.product_card;
  // Product Card (Algolia) Carousel
  else if (variation === 'cardsProductsAlgolia') sliceName = cardTypeNames.product_card;
  // Blog TTD Carousel/Column/Grid
  else if (variation === 'cardsPostLinks') sliceName = cardTypeNames.basic_card;
  // Category Links, Custom Content - based from CMS setting
  else if (cardType in cardTypeNames) sliceName = cardTypeNames[cardType];

  addToDataLayer({
    event: 'userInteraction',
    eventCategory: `${pageType} Page CMS`,
    eventAction: `${action} ${sliceName} | ${slug}`, // Clicked {cardType / nugget / product} | {currentPageSlug}
    eventLabel: label, // {heroTitle / productName}
  });
}

export function filter({ slug, label }: { slug: string; label: string }) {
  addToDataLayer({
    event: 'userInteraction',
    eventCategory: 'Category Interactions',
    eventAction: `Clicked Filter | ${slug}`,
    eventLabel: label, //'{Add Category / Remove Location}'
  });
  //  addToDataLayer({ event: 'algoliaInsightsFilterClick' });
}

export function sort({ slug, label }: { slug: string; label: string }) {
  addToDataLayer({
    event: 'userInteraction',
    eventCategory: 'Category Interactions',
    eventAction: `Clicked Sorting | ${slug}`,
    eventLabel: label, // '{Sort By New / Lowest to Highest}'
  });
}

export function paginate({ slug, label }: { slug: string; label: string }) {
  addToDataLayer({
    event: 'userInteraction',
    eventCategory: 'Category Interactions',
    eventAction: `Clicked Pagination | ${slug}`,
    eventLabel: label, // '{Next Page / Prev Page / Specific Page}',
  });
}

export function hit({ list, position, hit }: { position: number; list: string; hit: any }) {
  clickProduct({
    list,
    product: hitImpression(hit, position, list),
  });
  // addToDataLayer({ event: 'algoliaInsightsHitClick' });
}

export function locationOverrideInteraction({
  currentSlug,
  label,
}: {
  currentSlug: string;
  label: string;
}) {
  addToDataLayer({
    event: 'userInteraction',
    eventCategory: 'Category Interactions',
    eventAction: `Clicked Location Override | ${currentSlug}`,
    eventLabel: label,
  });
}

/**
 * Product Card interactions
 * - Swipe to next image
 * - Swipe to previous image
 * - Click next image
 * - Click previous image
 * - Clicked on image
 * - Clicked on surrounding card
 */
export function productCard(props: ProductInteractionProps) {
  const {
    // additional source data
    pageType,
    module,
    position,
    location,
    imageInteracted,
    interaction,
    // product data
    title,
    sku,
    promocode,
    price: { displayPrice, rrp, percentOff } = {
      displayPrice: null,
      rrp: null,
      percentOff: null,
    },
    reviews: { averageRating } = { averageRating: null },
    productPromo: promo,
  } = props;
  const fullSku = promocode ? `${sku} ${promocode}` : sku;

  addToDataLayer({
    event: 'productCardInteraction',
    pc_page_type: pageType,
    pc_module: module,
    pc_position: position,
    pc_sku: fullSku,
    pc_title: title,
    pc_promo: promo?.config?.text,
    pc_average_rating: averageRating,
    pc_display_price: displayPrice,
    pc_rrp: rrp,
    pc_percent_off: percentOff,
    pc_location: location,
    pc_image_interacted: imageInteracted,
    pc_interaction: interaction,
  });
}

export enum ProductCardInteraction {
  SwipeNext = 'Swipe to next',
  SwipePrev = 'Swipe to previous',
  ClickNext = 'Click next',
  ClickPrev = 'Click previous',
  ClickImage = 'Clicked on image',
  ClickCard = 'Clicked on surrounding card',
}

export type ProductInteractionProps = {
  title: Product['title'];
  sku: Product['sku'];
  promocode: Product['promocode'];
  price: Product['price'];
  reviews: Pick<Product['reviews'], 'averageRating'>;

  // Home / Category Listing / Product/ Custom Site / Blog Home
  // can be overwritten from GA Config
  pageType?: string;
  // slice name
  module: string;
  // position in the list
  position: number;
  // current promo pill text
  productPromo: { config: { text: string } } | null;
  // location text override
  location: string;
  // position of the currently active image when interacted (e.g. 1 of 4)
  imageInteracted: string;
  interaction: ProductCardInteraction;
};
