import type { HTMLAttributes } from 'react';

import { cn } from '@virginexperiencedays/tailwind';

/**
 * A component that displays a Black Friday tag.
 * @returns JSX.Element
 */
export const BlackFridayTag = ({ className, ...props }: HTMLAttributes<HTMLParagraphElement>) => {
  return (
    <p className={cn(styles.base, className)} {...props}>
      BLACK FRIDAY DEAL
    </p>
  );
};

export const CyberMondayTag = ({ className, ...props }: HTMLAttributes<HTMLParagraphElement>) => {
  return (
    <p className={cn(styles.base, className)} {...props}>
      CYBER MONDAY DEAL
    </p>
  );
};

export const ChristmasTag = ({ className, ...props }: HTMLAttributes<HTMLParagraphElement>) => {
  return (
    <p className={cn(styles.base, styles.christmas, className)} {...props}>
      CHRISTMAS DEAL
    </p>
  );
};

export const EasterTag = ({ className, ...props }: HTMLAttributes<HTMLParagraphElement>) => {
  return (
    <p className={cn(styles.base, styles.easter, className)} {...props}>
      EASTER GIFTING
    </p>
  );
};

export const MothersDayTag = ({ className, ...props }: HTMLAttributes<HTMLParagraphElement>) => {
  return (
    <p className={cn(styles.base, styles.mothersDay, className)} {...props}>
      MOTHER&apos;S DAY SPECIAL
    </p>
  );
};

export const FathersDayTag = ({ className, ...props }: HTMLAttributes<HTMLParagraphElement>) => {
  return (
    <p className={cn(styles.base, styles.fathersDay, className)} {...props}>
      DAD APPROVED
    </p>
  );
};

export const ValentinesTag = ({ className, ...props }: HTMLAttributes<HTMLParagraphElement>) => {
  return (
    <p className={cn(styles.base, styles.valentines, className)} {...props}>
      VALENTINE&apos;S DEAL
    </p>
  );
};

const styles = {
  base: 'inline-block m-0 text-background-page bg-background-neutral-strong p-1 text-xs font-semibold leading-none rounded border border-transparent',
  christmas: 'text-white bg-background-primary',
  easter: 'text-grey-700 bg-primary-100',
  mothersDay: 'text-grey-700 bg-primary-100',
  fathersDay: 'bg-background-page text-neutral border border-solid border-border-neutral',
  valentines: 'text-primary-500 bg-[#ffdcdd]',
};
