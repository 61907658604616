export const IconLocation = ({ size = '16px', className = null, fill = '#E10A0A', ...rest }) => {
  return (
    <svg
      width={size}
      height={size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 9 12"
      {...rest}
      className={className}
    >
      <path
        fill={fill}
        d="M2.42172 10.7805C2.27999 11.1659 1.7191 11.1131 1.65173 10.7081L0.112139 1.45221C0.0514945 1.08762 0.475424 0.842858 0.760847 1.07767L8.00687 7.03895C8.32394 7.2998 8.0892 7.81193 7.68461 7.742L4.11558 7.12516C3.92531 7.09228 3.73871 7.20001 3.67205 7.38123L2.42172 10.7805Z"
      />
    </svg>
  );
};
