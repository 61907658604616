export function getCardBadgeText({
  isNew,
  isExclusive,
}: {
  isNew: boolean;
  isExclusive: boolean;
}): 'NEW' | 'EXCLUSIVE' | null {
  if (isExclusive) return 'EXCLUSIVE';
  else if (isNew) return 'NEW';
  else return null;
}
