import type { ImpressionType } from '../types';
import { addToDataLayer } from '../utils';

type ClickProductArgs = {
  list: string;
  product: ImpressionType;
};

export default ({ list, product }: ClickProductArgs) => {
  addToDataLayer({ ecommerce: null }); // clear before every product click
  addToDataLayer({
    event: 'productClick',
    ecommerce: {
      click: {
        actionField: { list },
        products: [product],
      },
    },
  });
};
