import { useAspectRatioSupported } from '@virginexperiencedays/hooks';
import { cn } from '@virginexperiencedays/tailwind';

import { Image } from '../../layout/Image';
import type { PromotionBadgeType } from '../../PromotionBadge';

import { getCardBadgeText } from '../../utils/getCardBadgeText';
import { ProductPromoBadge, productPromoTag } from '../../utils/productPromoUtils';
import { mapAspectRatio, mapAspectRatioPolyfill } from '../../utils/mapAspectRatio';

import type { CardImageProps } from './types';
import { PromotionTag } from '../Product/PromotionTag';

export const ProductCardImage = ({
  cardImage,
  cardTitle,
  isOnSale,
  isNew,
  isExclusive,
  isBlackFridayProduct,
  isCyberMondayProduct,
  isChristmasProduct,
  isEasterProduct,
  isMothersDayProduct,
  isFathersDayProduct,
  isValentinesProduct,
  aspectRatio,
  priority,
  hasZoomOnHover,
}: CardImageProps) => {
  const { isSupported } = useAspectRatioSupported();

  const promos = {
    isBlackFridayProduct,
    isCyberMondayProduct,
    isChristmasProduct,
    isEasterProduct,
    isMothersDayProduct,
    isFathersDayProduct,
    isValentinesProduct,
  };

  const promoTag = productPromoTag(promos) as PromotionBadgeType;
  const badgeText = getCardBadgeText({ isNew, isExclusive });
  const PromoBadge = ProductPromoBadge(promos);

  const isPromoBadgeTextOnly = !!promoTag && !PromoBadge;

  return (
    <div data-testid="product-card-image" className="relative">
      <div
        className={cn(
          'bg-background-neutral relative h-full overflow-hidden',
          isSupported && mapAspectRatio(aspectRatio),
          !isSupported && mapAspectRatioPolyfill(aspectRatio)
        )}
        data-ratio={aspectRatio}
      >
        <Image
          className={cn(
            'image:object-cover duration-400 h-full',
            hasZoomOnHover &&
              'transition-all transition-transform will-change-transform group-hover:scale-105'
          )}
          src={cardImage}
          alt={cardTitle}
          priority={priority}
          fill
        />
        {/* if there is promo with badge text only, should not show any other badges */}
        {isPromoBadgeTextOnly ? (
          <>
            {/* (Top Left) Promo Badge Text */}
            <PromotionTag type={promoTag} className={styles.badgeBase} />
          </>
        ) : (
          <>
            {/* (Top Left) Exclusive / New badge */}
            {badgeText && (
              <span
                data-testid="product-card-corner-badge"
                className={cn(styles.badgeBase, styles.badgeDefault)}
              >
                {badgeText}
              </span>
            )}

            {/* (Lower Left) Sale Badge */}
            {isOnSale && (
              <span
                data-testid="product-card-sale-badge"
                className="bg-primary-500 absolute bottom-0 left-0 px-1 py-0.5 text-xs font-semibold uppercase leading-none text-white"
              >
                SALE
              </span>
            )}

            {/* (Lower Right) Promo Badge Icon */}
            {!!PromoBadge && <PromoBadge />}
          </>
        )}
      </div>
    </div>
  );
};

export const styles = {
  badgeBase: 'absolute left-2 top-2',
  badgeDefault:
    'bg-background-page text-neutral border-border-neutral self-start rounded border px-1 pb-0.5 pt-[calc(0.125rem_+_1px)] text-xs font-semibold leading-none',
};
