import { impression } from '../../../tracking/gtm/utils';
import { GLOBAL_SETTINGS } from '../../../tracking/gtm/consts';
import type { ImpressionType } from '../../../tracking/gtm/types';
import { useTracking } from '../../../tracking/useTracking';
import { pageView as trackFbPageView } from '../../../tracking/fb/events';

import type { Product } from '@virginexperiencedays/products';
import type { AlgoliaHit } from '@virginexperiencedays/search/types';
import { slugToName } from '@virginexperiencedays/search/utils/category';

import { getCookieMap } from '../../../../utils/cookie';
import { useRouteStorage } from '../../../../utils/storage';
import { useValidB2bRoute } from '../../../../utils/b2b/useValidB2bRoute';

type Slice = { slice_type: string; variation: string; primary?: { title: string }; items?: any[] };

type ImpressionTrackingArgs = {
  slices: Slice[];
  topProducts: Product[];
  list: string;
};

export const makeImpressions = ({
  topProducts = [],
  slices = [],
  list,
}: ImpressionTrackingArgs) => {
  const mapItems = (items, title) =>
    items.map((item, index) => impression(item, index + 1, `${list} | Carousel | ${title}`));

  const items =
    slices?.reduce(
      (filtered: ImpressionType[][], { slice_type, variation, items, primary: { title } }) => {
        // multiple set of products
        if (slice_type === 'carousel_with_tabs') {
          return filtered.concat(items.map((categories) => mapItems(categories.items, title)));
        }

        // single set of products
        if (['cardsProductIDs', 'cardsProductsAlgolia'].includes(variation)) {
          return filtered.concat([mapItems(items, title)]);
        }

        return filtered;
      },
      []
    ) ?? [];

  topProducts?.length &&
    items.push(
      topProducts.map((item, index) =>
        impression(item, index + 1, `Footer Carousel | Top Experiences`)
      )
    );

  return items;
};

export default function ({ slug, topProducts = [], slices = [], list, legacyPageType = '' }) {
  const impressions = makeImpressions({
    topProducts: Object.values(topProducts),
    slices,
    list,
  });

  const categoryName = slugToName(slug);

  // stores slug in storage if B2B
  useValidB2bRoute(slug);

  // leave previousCategory so PDP knows what category it was clicked from
  useRouteStorage(
    {
      previousCategory: {
        categoryName: list,
        // set expiry to be 3 days from now, in ISO string format to mirror C#
        expiry: new Date(Date.now() + 3 * 60 * 60 * 1000).toISOString(),
      },
    },
    [list]
  );

  useTracking({
    legacy: {
      pageName: categoryName,
      pageType: legacyPageType,
      categoryName,
    },
    gtm: {
      getInitial: ({ router }) => {
        const isAffinity =
          // check affinity cookie
          getCookieMap()?.has('affinity') ||
          // double check router.query as a fallback
          !!router?.query?.bid;

        return [
          {
            ...GLOBAL_SETTINGS,
            pageType: 'Category Page',
            pageCategory: slug,
            ...(isAffinity && { environment: 'Affinity' }),
          },
          {
            event: 'genericEvent',
            eventCategory: 'Category performance',
            eventAction: slug,
            eventLabel: null,
            nonInteractionEvent: 'True',
          },
          ...impressions.flatMap((impressions) => [
            { ecommerce: null }, // clear before every product slice impressions
            {
              event: 'ec:impressions',
              ecommerce: {
                currencyCode: 'GBP',
                impressions,
              },
            },
          ]),
        ];
      },
    },
    fb: () => {
      trackFbPageView({
        name: categoryName,
      });
    },
  });
}

export function hitImpression(hit: any, position: number, list: string) {
  const { title, price, sku, promocode, categoryHierarchy } = hit as unknown as AlgoliaHit;

  return impression(
    {
      title,
      sku,
      promocode,
      price,
      suppliers: [
        {
          name: 'Virgin Experience Days',
        },
      ],
      categoryHierarchy,
    } as unknown as Product,
    position,
    list
  );
}
