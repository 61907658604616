import {
  BlackFridayTag,
  CyberMondayTag,
  ChristmasTag,
  EasterTag,
  MothersDayTag,
  FathersDayTag,
  ValentinesTag,
} from '../../PromotionBadge/PromotionTag';
import type { PromotionBadgeType } from '../../PromotionBadge';

type Props = {
  type: PromotionBadgeType;
  className?: string;
};

export const PromotionTag = ({ className, type }: Props) => {
  switch (type) {
    case 'fathers-day':
      return <FathersDayTag className={className} />;
    case 'easter':
      return <EasterTag className={className} />;
    case 'mothers-day':
      return <MothersDayTag className={className} />;
    case 'black-friday':
      return <BlackFridayTag className={className} />;
    case 'cyber-monday':
      return <CyberMondayTag className={className} />;
    case 'christmas':
      return <ChristmasTag className={className} />;
    case 'valentines':
      return <ValentinesTag className={className} />;
    default:
      return null;
  }
};
