export const IconFavourite = ({ ...rest }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.49094 1.64964C4.1279 1.3858 4.81059 1.25 5.50004 1.25C6.18948 1.25 6.87218 1.3858 7.50914 1.64964C8.1461 1.91348 8.72486 2.3002 9.21237 2.78771L10 3.57538L10.7877 2.78771C11.7723 1.80314 13.1076 1.25001 14.5 1.25001C15.8924 1.25001 17.2278 1.80314 18.2124 2.78771C19.1969 3.77228 19.7501 5.10765 19.7501 6.50004C19.7501 7.89243 19.1969 9.2278 18.2124 10.2124L10.5304 17.8944C10.3897 18.035 10.199 18.114 10 18.114C9.80113 18.114 9.61036 18.035 9.46971 17.8944L1.78771 10.2124C1.3002 9.72486 0.91348 9.1461 0.649638 8.50914C0.385797 7.87218 0.25 7.18948 0.25 6.50004C0.25 5.81059 0.385797 5.1279 0.649638 4.49094C0.913478 3.85398 1.30019 3.27522 1.78771 2.78771L2.84837 3.84837C3.19659 3.50014 3.60999 3.22391 4.06497 3.03546C4.51994 2.847 5.00758 2.75 5.50004 2.75C5.9925 2.75 6.48014 2.847 6.93511 3.03546C7.39009 3.22391 7.80349 3.50014 8.15171 3.84837L9.46971 5.16637C9.61036 5.30702 9.80113 5.38604 10 5.38604C10.199 5.38604 10.3897 5.30702 10.5304 5.16637L11.8484 3.84837C12.5516 3.1451 13.5055 2.75001 14.5 2.75001C15.4946 2.75001 16.4484 3.1451 17.1517 3.84837C17.855 4.55164 18.2501 5.50547 18.2501 6.50004C18.2501 7.49461 17.855 8.44844 17.1517 9.15171L10 16.3034L2.84837 9.15171C2.50014 8.80349 2.22391 8.39009 2.03546 7.93511C1.847 7.48014 1.75 6.9925 1.75 6.50004C1.75 6.00758 1.847 5.51994 2.03546 5.06497C2.22391 4.60999 2.50015 4.19659 2.84837 3.84837L1.78771 2.78771M3.49094 1.64964C2.85398 1.91348 2.27521 2.3002 1.78771 2.78771Z"
      fill="currentColor"
    />
  </svg>
);
