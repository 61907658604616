import type { IconProps as Props } from './types';

export const IconLocationArrow = ({ color = 'var(--text-neutral)', ...rest }: Props) => {
  return (
    <svg
      {...rest}
      width="13"
      height="16"
      viewBox="0 0 13 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.52192 15.4375C3.38018 15.8228 2.8193 15.77 2.75193 15.365L0.371184 1.0522C0.310539 0.687613 0.73447 0.442857 1.01989 0.677673L12.2248 9.89587C12.5418 10.1567 12.3071 10.6688 11.9025 10.5989L6.02463 9.58304C5.83435 9.55016 5.64775 9.65789 5.58109 9.83912L3.52192 15.4375Z"
        fill={color}
      />
    </svg>
  );
};
