import { cn } from '@virginexperiencedays/tailwind';

import { Carousel } from '../../layout/Carousel';
import { DisplayHeading } from '../../typography/DisplayHeading';
import { BodyText } from '../../typography/BodyText';
import { EditorsPickCard } from '../../cards/EditorsPick';
import { ProductCardV2, type ProductCardV2Props } from '../../cards/ProductV2';
import { generateMultipleParagraphs } from '../../utils/generateMultipleParagraphs';

import type { Product } from '@virginexperiencedays/products';
import type { MouseEvent } from 'react';

export type EditorsPickProps = {
  title: string;
  description: string[];
  productsList: (Product & {
    onClick?: (e: MouseEvent<HTMLElement>) => void;
  })[];
  className?: string;
  trackClassName?: string;
  cardImageClassName?: string;
  isProductCardsV2?: boolean;
};

export const EditorsPick = ({
  title,
  description,
  productsList,
  className,
  trackClassName,
  cardImageClassName,
  isProductCardsV2,
}: EditorsPickProps) => {
  if (productsList?.length < 1) return null;

  return (
    <div className={className}>
      <div>
        {title && (
          <DisplayHeading className="text-neutral-strong mb-2" size="2">
            {title}
          </DisplayHeading>
        )}
        {description && (
          <BodyText className="text-neutral mb-6">
            {description.length > 1 ? generateMultipleParagraphs(description) : description}
          </BodyText>
        )}
      </div>
      <Carousel
        trackClassName={cn('children:min-w-[240px] children:max-w-[240px]', trackClassName)}
        withHeading={false}
        perPage={{
          tablet: 3,
          desktop: 4,
        }}
      >
        {productsList.map((product) => {
          if (isProductCardsV2) {
            return (
              <ProductCardV2
                key={product.id}
                {...(product as Omit<ProductCardV2Props, 'displayPrice' | 'images'>)}
                averageRating={product.reviews.averageRating}
                displayPrice={product.price.displayPrice}
                rrp={product.price.rrp}
                percentOff={product.price.percentOff}
                titleAs="h3"
                images={[product.media.mainImage, ...(product.media.images || [])].map(
                  (image, i) => ({
                    src: image.url,
                    alt: image.altText || `${product.title} ${i + 1}`,
                  })
                )}
              />
            );
          }

          return (
            <EditorsPickCard
              key={product.id}
              product={product}
              cardImageClassName={cardImageClassName}
            />
          );
        })}
      </Carousel>
    </div>
  );
};
