import {
  type ButtonHTMLAttributes,
  type MouseEvent,
  useCallback,
  useEffect,
  useState,
} from 'react';

import { cn } from '@virginexperiencedays/tailwind';
import { IconAngleLeft } from '../../icons/IconAngleLeft';
import { IconAngleRight } from '../../icons/IconAngleRight';
import { CarouselCallbackProps } from './EmblaCarousel';

import type { EmblaCarouselType } from 'embla-carousel';

type UsePrevNextButtonsType = {
  prevBtnDisabled: boolean;
  nextBtnDisabled: boolean;
  onPrevButtonClick: (e: MouseEvent<HTMLButtonElement>, currentIndex: number) => void;
  onNextButtonClick: (e: MouseEvent<HTMLButtonElement>, currentIndex: number) => void;
};

export type UsePrevNextButtonsPropsData = {
  onNavClick?: (data: CarouselCallbackProps) => void;
  totalSlides: number;
};

export const usePrevNextButtons = (
  emblaApi: EmblaCarouselType | undefined,
  { onNavClick, totalSlides }: UsePrevNextButtonsPropsData
): UsePrevNextButtonsType => {
  const [prevBtnDisabled, setPrevBtnDisabled] = useState(true);
  const [nextBtnDisabled, setNextBtnDisabled] = useState(true);

  const onPrevButtonClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>, currentIndex = 0) => {
      e.preventDefault();
      e.stopPropagation();

      if (!emblaApi) return;
      emblaApi.scrollPrev();

      const nextSelected = currentIndex - 1;
      onNavClick?.({
        currentIndex,
        selectedIndex: nextSelected < 0 ? totalSlides - 1 : nextSelected,
        totalSlides,
        dir: 'prev',
        gesture: 'click',
      });
    },
    [emblaApi]
  );

  const onNextButtonClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>, currentIndex = 0) => {
      e.preventDefault();
      e.stopPropagation();

      if (!emblaApi) return;
      emblaApi.scrollNext();

      const nextSelected = currentIndex + 1;
      onNavClick?.({
        currentIndex,
        selectedIndex: nextSelected > totalSlides ? 0 : nextSelected,
        totalSlides,
        dir: 'next',
        gesture: 'click',
      });
    },
    [emblaApi]
  );

  const onSelect = useCallback((emblaApi: EmblaCarouselType) => {
    setPrevBtnDisabled(!emblaApi.canScrollPrev());
    setNextBtnDisabled(!emblaApi.canScrollNext());
  }, []);

  useEffect(() => {
    if (!emblaApi) return;

    onSelect(emblaApi);
    emblaApi.on('reInit', onSelect).on('select', onSelect);
  }, [emblaApi, onSelect]);

  return {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,
  };
};

export const ControlButton = ({
  direction,
  size = 'default',
  className,
  ...props
}: ButtonHTMLAttributes<HTMLButtonElement> & {
  size?: 'default' | 'large';
  direction: 'next' | 'prev';
}) => {
  const arrowHeight = size === 'large' ? '12' : '10';
  const arrowWidth = size === 'large' ? '8' : '6';

  return (
    <button
      {...props}
      className={cn(
        `embla__button embla__button--${direction} group cursor-pointer bg-transparent px-2 py-8`,
        className
      )}
      type="button"
    >
      <div
        className={cn(
          'border-border-neutral group-hover:bg-background-neutral-strong bg-background-page flex h-8 w-8 items-center justify-center rounded-full border transition-all duration-300 ease-in-out',
          size === 'large' && 'h-12 w-12'
        )}
      >
        {direction === 'prev' ? (
          <IconAngleLeft
            width={arrowWidth}
            height={arrowHeight}
            className="group-hover:[&>path]:stroke-background-page"
          />
        ) : (
          <IconAngleRight
            width={arrowWidth}
            height={arrowHeight}
            className="group-hover:[&>path]:stroke-background-page"
          />
        )}
      </div>
    </button>
  );
};
